
import Vue from "vue";
import rules from "@/components/account/rules";
import stepFielfdMixin from "../stepFielfdMixin";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "video-photo-autorization",
  mixins: [stepFielfdMixin],
  data() {
    return {
      rules,
      patientType: 1,
      fields: {
        isEmployee: false, //
        isPatient: true, //
        other: false, //
        participantName: "", //
        phoneParticipant: "", // 17868027461
        parentName: "", //
        phoneParent: "", // 17868027461
      },
    };
  },
  watch: {
    patientType(value: number): void {
      switch (value) {
        case 0:
          this.fields.isEmployee = true;
          this.fields.isPatient = false;
          this.fields.other = false;
          break;
        case 1:
          this.fields.isEmployee = false;
          this.fields.isPatient = true;
          this.fields.other = false;
          break;
        case 2:
          this.fields.isEmployee = false;
          this.fields.isPatient = false;
          this.fields.other = true;
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["getpatientN"]),
  },
});
